import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import ReactDOM from 'react-dom'
import dynamic from 'next/dynamic'
import type { Hit } from 'react-instantsearch-core'
import dayjs from 'dayjs'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useDispatch, useSelect } from 'store/index'

import { setActiveMarker } from 'reducers/searchData'
import { setMapInfowindowClicked } from 'reducers/uiState'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import CustomLink from 'components/Link/CustomLink'
import StarRatings from 'components/StarRatings/StarRatings'
import FavoriteButton from 'components/Favorites/FavoriteButton'
import ResultCarousel from 'components/Carousels/ResultCarousel'

import style from './AlgoliaMarker.module.scss'

import { calcRating, setPrice } from 'utils/Strings'
import {
  buildListingLink,
  calculateTotalPriceForHit,
  getPriceRateByDateRange,
} from 'utils/Listings'
import { getInfowindowOffset } from 'utils/Map'

import dateFormats from 'constants/dates'

import type { AlgoliaListing } from 'types/externalData'

const CustomMarker = dynamic(() => import('./CustomMarker'), { ssr: false })

type AlgoliaMarkerProps = {
  hit: Hit<AlgoliaListing>
  cardRef: React.RefObject<HTMLDivElement>
  googleMapsInstance?: google.maps.Map<HTMLDivElement>
}

// Wrapping in withGoogleMaps to pass google maps instance
const AlgoliaMarker = ({
  hit,
  cardRef,
  googleMapsInstance,
}: AlgoliaMarkerProps) => {
  const { query } = useAugmentedRouter()
  // Redux
  const width = useSelect((state) => state.uiState.width)
  const activeView = useSelect((state) => state.uiState.activeView)
  const activeMarker = useSelect((state) => state.searchData.activeMarker)
  const hoveredHit = useSelect((state) => state.uiState.selectedHit)
  const startDate = useSelect((state) => state.search.selectedDates?.start)
  const endDate = useSelect((state) => state.search.selectedDates?.end)
  const hasPetsSelected = useSelect((state) => state.search.selectedPets)

  const showAveragePricePerNight = useFeatureIsOn('average-price-per-night')

  const selectedListings = useSelect(
    (state) => state.favorites.selectedListings,
  )
  const appDispatch = useDispatch()

  const active = activeMarker === hit.objectID
  const highlight = hoveredHit === hit.objectID
  const calculatedRating = calcRating(hit['Average Rating'].toString())
  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null)
  // div for Portal to render InfoWindow content into
  const containerRef = useRef(document.createElement('div'))
  const isFavorited = selectedListings?.some(
    (selected) => selected.objectID === hit.objectID,
  )

  useEffect(() => {
    infoWindowRef.current = new window.google.maps.InfoWindow({
      disableAutoPan: true,
    })
  }, [])

  const markerClick = useCallback(
    (marker) => {
      if (width > 900 && infoWindowRef.current) {
        if (marker.getMap()) {
          infoWindowRef.current?.close()
        }

        // set content of InfoWindow to div which Portal is rendered into
        infoWindowRef.current.setContent(containerRef.current)
        // adjust InfoWindow offset based on marker position
        googleMapsInstance &&
          infoWindowRef.current.setOptions({
            pixelOffset: getInfowindowOffset(googleMapsInstance, marker),
          })
        infoWindowRef.current.open(marker.getMap() ?? undefined, marker)
      }

      if (!active) {
        appDispatch(setActiveMarker(hit.objectID))
      } else {
        appDispatch(setActiveMarker(null))
      }
    },
    [active, appDispatch, googleMapsInstance, hit.objectID, width],
  )

  const displayPrice = useMemo(() => {
    if (showAveragePricePerNight) {
      let priceToDisplay = 0
      if (startDate && endDate) {
        priceToDisplay = getPriceRateByDateRange(
          startDate,
          endDate,
          hit.PricePerDay,
          true,
        )
      } else {
        priceToDisplay = hit['Average Per Night']
      }

      return setPrice(priceToDisplay)
    } else {
      let priceToDisplay = 0
      // These are simulating what will be A/B tests
      const STAY_LENGTHS = [3, 4, 5]
      const conditionA = false
      const conditionB = false
      const conditionC = true

      const getStayLength = () => {
        if (conditionA) {
          return STAY_LENGTHS[0]
        } else if (conditionB) {
          return STAY_LENGTHS[1]
        } else if (conditionC) {
          return STAY_LENGTHS[2]
        }
        return 0
      }

      const stayLength =
        startDate && endDate
          ? dayjs(endDate, dateFormats.DEFAULT).diff(
              dayjs(startDate, dateFormats.DEFAULT),
              'days',
            )
          : getStayLength()

      priceToDisplay = calculateTotalPriceForHit(
        hit,
        startDate,
        endDate,
        stayLength,
        hasPetsSelected,
      )
      return setPrice(Math.ceil(priceToDisplay))
    }
  }, [endDate, hit, startDate, hasPetsSelected, showAveragePricePerNight])

  const listingLinkFromUrl = useMemo(
    () => buildListingLink(hit, query),
    [hit, query],
  )

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      // if click is outside info container OR outside marker and card
      if (
        activeMarker === hit.objectID &&
        ((containerRef.current &&
          !containerRef.current.contains(e.target) &&
          activeView !== 'map') ||
          (!containerRef.current &&
            cardRef.current &&
            !cardRef.current.contains(e.target)))
      ) {
        // unselect marker
        infoWindowRef.current?.close()
        appDispatch(setActiveMarker(null))
      }
    }

    window.addEventListener('mousedown', handleClickOutside)
    return () => window.removeEventListener('mousedown', handleClickOutside)
  }, [activeMarker, activeView, appDispatch, cardRef, hit.objectID])

  return (
    <>
      {active &&
        ReactDOM.createPortal(
          <div className={style.infoContainer}>
            <FavoriteButton container="algoliaMarker" listing={hit} />
            <CustomLink
              href={listingLinkFromUrl}
              id={`listing_${hit['objectID']}`}
              onClick={() => appDispatch(setMapInfowindowClicked(hit.objectID))}
              prefetch={false}
              rel="noreferrer"
              target="_blank"
            >
              {hit.images?.length && <ResultCarousel hit={hit} mapDisplay />}
              <div className={style.info}>
                <div className={style.infoTop}>
                  <span className={style.type}>{hit['Property Type']}</span>
                  {calculatedRating > 0 && (
                    <div className={style.resultDetailRating}>
                      <div className={style.starRating}>
                        <span>{calculatedRating}</span>
                        <StarRatings
                          numberOfStars={5}
                          rating={calculatedRating}
                          starDimension="10px"
                          starRatedColor="#F9A01F"
                          starSpacing="1px"
                          uniqueId="markerRating"
                        />
                      </div>
                      <span className={style.reviews}>
                        ({calcRating(hit['Number of Reviews'].toString())})
                      </span>
                    </div>
                  )}
                </div>
                <div className={style.resultDetailsDescriptionChild}>
                  <span className={style.resultHeadline}>
                    {hit['Headline']}
                  </span>
                </div>
                <div className={style.resultDetailInfo}>
                  <span>{hit['Bedrooms']} BR</span>
                  <span>{hit['Bathrooms']} BA</span>
                  <span>Sleeps {hit['Max Occupancy']}</span>
                </div>
              </div>
            </CustomLink>
          </div>,
          containerRef.current,
        )}
      <CustomMarker
        className={`${style.customMarker} ${
          isFavorited ? style.favorited : ''
        }`}
        highlight={active || highlight}
        hit={hit}
        key={hit.objectID}
        onClick={markerClick}
        price={displayPrice}
      />
    </>
  )
}

export default React.memo(AlgoliaMarker)
