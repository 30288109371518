import React, { useCallback, useEffect } from 'react'
import isEqual from 'react-fast-compare'
import type { HitsProvided } from 'react-instantsearch-core'
import dayjs from 'dayjs'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useSelect } from 'store/index'

import AlgoliaResult from './AlgoliaResult'

import {
  calculateTotalPriceForHit,
  getPriceRateByDateRange,
} from 'utils/Listings'

import dateFormats from 'constants/dates'

import type { AlgoliaListing } from 'types/externalData'

const STAY_LENGTHS = [3, 4, 5]

// Custom Hits Component
// Following Algolia documentation so data can be shared
// between the map and search results
const AlgoliaHits = React.memo(({ hits }: HitsProvided<AlgoliaListing>) => {
  const numHits = useSelect((state) => state.search.numHits)
  const startDate = useSelect((state) => state.search.selectedDates?.start)
  const endDate = useSelect((state) => state.search.selectedDates?.end)
  const hasPetsSelected = useSelect((state) => state.search.selectedPets)

  const showAveragePricePerNight = useFeatureIsOn('average-price-per-night')

  const calculatePrice = React.useCallback(
    (hit: AlgoliaListing, isTotal: boolean = false): number => {
      if (startDate && endDate) {
        const price = getPriceRateByDateRange(
          startDate,
          endDate,
          hit.PricePerDay,
          isTotal,
        )

        return isTotal ? price : Math.round(price)
      }
      return hit['Average Per Night'] || 0
    },
    [endDate, startDate],
  )

  const conditionA = false
  const conditionB = false
  const conditionC = true

  const getStayLength = () => {
    if (conditionA) {
      return STAY_LENGTHS[0]
    } else if (conditionB) {
      return STAY_LENGTHS[1]
    } else if (conditionC) {
      return STAY_LENGTHS[2]
    }
    return 0
  }

  const stayLength =
    startDate && endDate
      ? dayjs(endDate, dateFormats.DEFAULT).diff(
          dayjs(startDate, dateFormats.DEFAULT),
          'days',
        )
      : getStayLength()

  const calculateTotalPrice = useCallback(
    (hit: AlgoliaListing) => {
      return calculateTotalPriceForHit(
        hit,
        startDate,
        endDate,
        stayLength,
        hasPetsSelected,
      )
    },
    [startDate, endDate, hasPetsSelected, stayLength],
  )

  const calculatePage = (index: number) => {
    return Math.ceil((index + 1) / 20)
  }

  useEffect(() => {
    if (numHits === null || (numHits > 0 && !hits.length)) {
      return
    }

    const timer = setTimeout(() => {
      window.analytics.track('Product List Viewed', {
        query: window.location.href.split('?')[1] || '',
        results_count: numHits,
        results_page: calculatePage(hits[0]?.__position || 1),
        products: hits.map((hit) => ({
          product_id: hit.objectID,
          category: hit['Property Type'] || '',
          price: calculateTotalPrice(hit),
          position: hit.__position || 1,
          review_score: hit['Average Rating'] || 0,
          review_count: hit['Number of Reviews'] || 0,
        })),
      })
    }, 800)

    return () => clearTimeout(timer)
  }, [hits, numHits])

  return (
    <>
      {hits.length
        ? hits.map((hit, i) => {
            const totalPrice = showAveragePricePerNight
              ? calculatePrice(hit, true)
              : calculateTotalPrice(hit)
            const averagePricePerNight = calculatePrice(hit)
            const calculateAvgPriceWithFees = () => totalPrice / stayLength

            return (
              <AlgoliaResult
                calculatePrice={
                  showAveragePricePerNight
                    ? calculatePrice
                    : calculateAvgPriceWithFees
                }
                hit={hit}
                key={hit.objectID}
                resultIndex={i}
                totalPrice={totalPrice}
              />
            )
          })
        : null}
    </>
  )
}, isEqual)

AlgoliaHits.displayName = 'AlgoliaHits'

export default AlgoliaHits
