import dayjs from 'dayjs'

// constants
import dateFormats from 'constants/dates'

export const formatPostDate = (date: string) => {
  return dayjs(date).format(dateFormats.MONTH_NAME_LARGE_FORMAT)
}

export const formatDateGtm = (date: string) => {
  return dayjs(date).format(dateFormats.SPLIT_BY_DASH)
}

export const getDatesDiffInDays = (date1: Date, date2: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

const suffixMap = {
  1: 'st',
  2: 'nd',
  3: 'rd',
}

export const getUntilDateFormats = (checkInDate: string): Array<string> => {
  // Get the current time and the checkin time and convert times to whole day units
  // (this cuts down on users seeing false info from timezone differences and accounts for checkin date being pegged to a day not a specific time)
  // const checkInDay: number = Math.floor(Date.parse(checkInDate) / 86400000)
  const checkInDay: number = Date.parse(checkInDate).valueOf()
  // gets and formats the date of two weeks before check in
  const twoWeekDate = checkInDay - 14 * 86400000
  let formattedDateShort = new Date(twoWeekDate).toLocaleString('default', {
    month: 'short',
    day: 'numeric',
  })
  let formattedDateLong = new Date(twoWeekDate).toLocaleString('default', {
    month: 'long',
    day: 'numeric',
  })
  const formattedDateYear = new Date(twoWeekDate).toLocaleString('default', {
    year: 'numeric',
  })
  const formattedDateDay = parseInt(formattedDateShort.split(' ')[1])
  if (formattedDateDay > 20 || formattedDateDay < 10) {
    formattedDateShort += suffixMap[formattedDateDay % 10] ?? 'th'
  } else {
    formattedDateShort += 'th'
  }
  formattedDateLong += ', ' + formattedDateYear

  return [formattedDateShort, formattedDateLong]
}
